import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import MyAccount from "./Pages/MyAccount/MyAccount";
import BusinessAccount from "./Pages/BusinessAccount/BusinessAccount";
import AnotherAccount from "./Pages/AnotherAccount/AnotherAccount";
import AddPost from "./Pages/AddPost/AddPost";
import Product from "./Pages/Product/Product";
import "./scss/style.scss";
import Header from "./layout/Header/Header";
import Popup from "./components/Popup/Popup";
import Footer from "./layout/Footer/Footer";
import { useEffect, useContext } from "react";
import { CustomContext } from "./Context";
import Profile from "./Pages/Profile/Profile";
import Category from "./Pages/Category/Category";

function App() {
  const { getUserFromLS, getAllProduct } = useContext(CustomContext);
  useEffect(() => {
    getUserFromLS();
  }, []);

  return (
    <div className="App">
      <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/myAccount" element={<MyAccount />} />
        <Route path="/businessAccount" element={<BusinessAccount />} />
        <Route path="/anotherAccount/:id" element={<AnotherAccount />} />
        <Route path="/category" element={<Category />} />
        <Route path="/addPost" element={<AddPost />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/profile/*" element={<Profile />} />
      </Routes>
      <Popup />
      <Footer />
    </div>
  );
}

export default App;
