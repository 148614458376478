import React from "react";
import { FaTrashCan } from "react-icons/fa6";
import { CustomContext } from "../../Context";
import { useForm } from "react-hook-form";
import axios from "../../axios";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const Settings = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(CustomContext);

  const { handleSubmit, register } = useForm();

  const updateUser = (data) => {
    axios
      .patch(`/users/${user.id}`, {
        name: data.name.length ? data.name : user.name,
        phone: data.phone.length ? data.phone : user.phone,
        aboutMe: data.aboutMe.length ? data.aboutMe : user.aboutMe,
        avatar: data.avatar.length ? data.avatar : user.avatar,
      })
      .then((res) => {
        axios(`/product?creator.id=${res.data.id}`).then((json) =>
          json.data.forEach((item) => {
            axios
              .patch(`/product/${item.id}`, {
                creator: res.data,
              })
              .then(() => {
                setUser(res.data);
                localStorage.setItem("user", JSON.stringify(res.data));
              });
          })
        );
      });
  };
  return (
    <div>
      <h2 className="profile__title">Фото профиля</h2>
      <form onSubmit={handleSubmit(updateUser)} action="">
        <div className="profile__content">
          <div className="profile__user">
            <img
              className="profile__img"
              src={
                user.avatar
                  ? user.avatar
                  : "https://www.atoz-catering.co.uk/assets/default-profile-picture.png"
              }
              alt=""
            />
            <input
              {...register("avatar")}
              className="profile__input"
              defaultValue={user.avatar}
              type="text"
              placeholder="Вставьте ссылку на изображение"
            />
            <p className="profile__icon">
              <FaTrashCan />
            </p>
          </div>
          <div className="profile__balance">
            <div className="profile__balance-content">
              <p className="profile__balance-title">Пополнение кошелька</p>
              <p className="profile__balance-title">1000 Р</p>
            </div>
            <button
              onClick={() => navigate("/profile/wallet")}
              class="profile__btn-save"
            >
              Пополнить
            </button>
          </div>
        </div>
        <p className="profile__info">Максимальный размер 1 мб</p>
        <h3 className="profile__title">Личная информация</h3>
        <p className="profile__title">Мое имя</p>

        <input
          {...register("name")}
          defaultValue={user.name}
          className="profile__input"
          placeholder="Введите имя"
          type="text"
        />
        <p className="profile__title">Email</p>
        <input
          readOnly
          defaultValue={user.email}
          className="profile__input"
          placeholder="Введите email"
          type="text"
        />
        <p className="profile__title">О себе</p>
        <input
          {...register("aboutMe")}
          defaultValue={user.aboutMe}
          className="profile__input-about"
          placeholder="Расскажите о себе"
          type="text"
        />
        <p className="profile__title">Номер телефона</p>
        <input
          {...register("phone")}
          defaultValue={user.phone}
          className="profile__input"
          placeholder="Введите номер телефона"
          type="tel"
        />
        <button className="profile__btn-save">Сохранить</button>
      </form>
      <h3 className="profile__title">Изменения пароля</h3>
      <p className="profile__title">Текущий пароль</p>
      <input
        className="profile__input"
        placeholder="Текущий пароль"
        type="password"
      />
      <p className="profile__title">Новый пароль</p>
      <input className="profile__input" placeholder="Пароль" type="password" />
      <p className="profile__title">Подтвердите пароль</p>
      <input className="profile__input" placeholder="Пароль" type="password" />
      <button className="profile__btn-save">Сохранить</button>
      <h3 className="profile__title">Удалить профиль</h3>
      <p className="profile__remove">
        <span>
          <FaTrashCan />
        </span>
        Удалить профиль
      </p>
    </div>
  );
};

export default Settings;
