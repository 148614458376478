import React, { useState } from "react";
import { RiArrowDownWideFill } from "react-icons/ri";
import { IoSearch } from "react-icons/io5";
import { arrItem } from "../../list";
import ProductItem from "./ProductItem";

const Category = () => {
  const [active, setActive] = useState(false);
  return (
    <div className="category">
      <div className="container">
        <div className="category__head">
          <div
            className="category__chose"
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          >
            <p className="category__chose-title">Все категории</p>
            <p className="category__chose-icon">
              <RiArrowDownWideFill />
            </p>
            <ul
              className={`category__list ${
                active ? "category__list-active" : ""
              }`}
            >
              {arrItem.map((item) => (
                <li className="category__item">{item.title}</li>
              ))}
            </ul>
          </div>
          <div className="category__search">
            <input
              className="category__search-input"
              placeholder="Я ищу"
              type="text"
            />
            <p className="category__search-icon">
              <IoSearch />
            </p>
          </div>
          <select className="category__select" name="" id="">
            <option value="">Выберите город</option>
            <option value="">Москва</option>
            <option value="">Санкт-Петербург</option>
            <option value="">Москва</option>
          </select>
        </div>
        <div className="category__content">
          <div className="category__price">
            <p className="category__title">Цена</p>
            <div className="category__range">
              <input
                className="category__range-input"
                placeholder="от"
                type="number"
              />
              <input
                className="category__range-input"
                placeholder="до"
                type="number"
              />
            </div>
          </div>
          <div className="category__currency">
            <label className="category__label" htmlFor="RUB">
              <span>Р.</span>
            </label>
          </div>
          <div className="category__sort">
            <p className="category__title">Сортировать</p>
            <select className="category__sort-select" name="" id="">
              <option value="">По умолчанию</option>
              <option value="">Сначала новые</option>
              <option value="">Сначала дешевле</option>
              <option value="">Сначала дороже</option>
            </select>
            <button className="category__btn">Показать</button>
          </div>
        </div>
      </div>
      <ProductItem />
    </div>
  );
};

export default Category;
