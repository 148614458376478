import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { CustomContext } from "../../Context";
import axios from "../../axios";

const Wallet = () => {
  const { user, setUser } = useContext(CustomContext);
  const { handleSubmit, reset, register } = useForm();

  const payFunc = (data) => {
    if (data.volume !== null) {
      axios
        .patch(`/users/${user.id}`, {
          balance: +user.balance + +data.volume,
        })
        .then((res) => {
          setUser(res.data);
          localStorage.setItem("user", JSON.stringify(res.data));
          reset();
        });
    } else {
      alert("Выбирите сумму");
    }
  };
  return (
    <div className="profile__wallet">
      <div className="container">
        <h2 className="profile__wallet-title">Кошелек</h2>
        <br />
        <p className="profile__wallet-info">
          Ваш балланс. <br />
          Пополните Ваш кошелек любом удобным для Вас спопсобом и платите за
          услуги на сайте
        </p>
        <form onSubmit={handleSubmit(payFunc)} className="profile__wallet-list">
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={5000}
            />
            <span className="profile__wallet-volume">5000 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={3000}
            />
            <span className="profile__wallet-volume">3000 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={2000}
            />
            <span className="profile__wallet-volume">2000 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={1500}
            />
            <span className="profile__wallet-volume">1500 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={1000}
            />
            <span className="profile__wallet-volume">1000 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={500}
            />
            <span className="profile__wallet-volume">500 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={300}
            />
            <span className="profile__wallet-volume">300 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={200}
            />
            <span className="profile__wallet-volume">200 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={150}
            />
            <span className="profile__wallet-volume">150 Р</span>
          </label>
          <label className="profile__wallet-label" htmlFor="">
            <input
              {...register("volume")}
              className="profile__wallet-input"
              type="radio"
              value={100}
            />
            <span className="profile__wallet-volume">100 Р</span>
          </label>
          <button className="profile__btn-save profile__wallet-btn" type="submit">
            Пополнить баланс
          </button>
        </form>
      </div>
    </div>
  );
};

export default Wallet;
