import axios from "../../axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserImg from "../../assets/user.png";

const Product = () => {
  const params = useParams();
  const [product, setProduct] = useState({});
  useEffect(() => {
    axios.get(`/product/${params.id}`).then(({ data }) => {
      setProduct(data);
    });
  });
  return (
    <div>
      <div className="product">
        <div className="container">
          <div className="product__crumbs">
            <Link className="product__crumbs-link" to="/">
              Home
            </Link>{" "}
            - <p className="product__crumbs-product">Product</p>
          </div>
          <div className="product__content">
            <div className="product__content-left">
              <img
                className="product__content-img"
                src={product.image}
                alt={product.title}
              />
            </div>
            <div className="product__content-right">
              <p className="product__content-price">{product.price}</p>
              <div className="product__content-user">
                <img
                  className="product__content-userImg"
                  src={UserImg}
                  alt=""
                />
                <p className="product__content-userName">User</p>
              </div>
              <ul className="product__content-right-list">
                {product.comment &&
                  product.comment.map((comment) => (
                    <li className="product__content-right-item">
                      <span className="product__content-right-email">
                        {comment.email}
                      </span>
                      - {comment.message}
                    </li>
                  ))}
              </ul>
              <button className="product__content-right-btn" type="button">
                Добавить комментарий
              </button>
              <p className="product__content-right-phone">+7(999)888-88-88</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
