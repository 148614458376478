import React from "react";

const Select = ({ title, list, formKey, register }) => {
  return (
    <div>
      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "20px" }}
      >
        {title}
      </h3>
      <select {...register(`${formKey}`)} style={{ padding: "5px 25px" }}>
        <option value="" select>
          Выберите
        </option>
        {list.map((item) => (
          <option value={item}>{item}</option>
        ))}
      </select>
    </div>
  );
};

export default Select;
