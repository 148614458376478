import React, { useContext, useEffect } from "react";
import { CustomContext } from "../../Context";
import Card from "../Home/Card";

const Posts = () => {
  const { user, product, getProductsById } = useContext(CustomContext);
  useEffect(() => {
    getProductsById(user.id);
  }, []);
  return (
    <div className="home__content">
      {product.map((item) => (
        <Card item={item} />
      ))}
    </div>
  );
};

export default Posts;
