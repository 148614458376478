import React from "react";

const ProductItem = () => {
  return (
    <div>
      <div className="container">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum,
        molestiae incidunt? Obcaecati quaerat, earum at voluptatibus a
        voluptatum odit illo ex nam porro beatae? Temporibus nulla porro magni
        corporis error!
      </div>
    </div>
  );
};

export default ProductItem;
