import React from "react";
import { Link, NavLink, Route, Routes, useLocation } from "react-router-dom";
import Settings from "./Settings";
import Posts from "./Posts";
import Messages from "./Messages";
import Wallet from "./Wallet";

const Profile = () => {
  const location = useLocation();
  return (
    <div className="profile">
      <div className="container">
        <div>
          <ul className="profile__tabs">
            <li>
              <NavLink className="profile__tabs-item" to={"/profile/posts"}>
                Мои объявления
              </NavLink>
            </li>
            <li>
              <NavLink className="profile__tabs-item" to={"/profile/messages"}>
                Сообщения
              </NavLink>
            </li>
            <li>
              <NavLink className="profile__tabs-item" to={"/profile/wallet"}>
                Кошелек
              </NavLink>
            </li>
            <li>
              <NavLink className="profile__tabs-item" to={"/profile/settings"}>
                Настройка профиля
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="product__crumbs">
          <Link to="/">главная</Link> - <p>Настройки профиля</p> -{" "}
          <p>
            {location.pathname.includes("settings")
              ? "Настройки профиля"
              : location.pathname.includes("wallet")
              ? "Кошелек"
              : location.pathname.includes("messages")
              ? "Сообщения"
              : location.pathname.includes("posts")
              ? "Мои объявления"
              : ""}
          </p>
        </div>
        <Routes>
          <Route path="/settings" element={<Settings />} />
          <Route path="/posts" element={<Posts />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/messages" element={<Messages />} />
        </Routes>
      </div>
    </div>
  );
};

export default Profile;
