import Card from "./Card";
import React, { useContext, useEffect } from "react";
import { CustomContext } from "../../Context";
import { useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const { user, product, getAllProduct } = useContext(CustomContext);
  useEffect(() => {
    getAllProduct();
  }, []);
  useEffect(() => {
    getAllProduct();
  });
  return (
    <div className="home">
      <div className="container">
        <div className="home__content">
          {product.map((item) => (
              <Card item={item} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
