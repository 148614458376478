import React, { useContext } from "react";
import { CustomContext } from "../../Context";
import { Link } from "react-router-dom";
import { MdFavorite, MdEmail } from "react-icons/md";

const Card = ({ item }) => {
  const { user } = useContext(CustomContext);

  return (
    <div key={item.id} className="home__card">
      <Link to={`/product/${item.id}`}>
        <img className="home__img" src={item.image} alt="" />
        <h2>{item.title}</h2>
        <p>{item.price}</p>
        <span>{item.descr}</span>
      </Link>
      <div className="home__info">
        <Link
          className="home__info-avatar"
          to={`/anotheraccount/${item.creator.id}`}
        >
          <img
            className="home__info-img"
            src={
              item.creator.avatar.length
                ? item.creator.avatar
                : "https://www.atoz-catering.co.uk/assets/default-profile-picture.png"
            }
            alt=""
          />
        </Link>
        <div className="home__info-icons">
          <Link className="home__info-icon" to="/">
            <MdEmail />
          </Link>
          <Link className="home__info-icon" to="/">
            <MdFavorite />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;
