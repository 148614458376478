import { createContext, useState } from "react";
import axios from "./axios";
export const CustomContext = createContext();

export const Context = (props) => {
  const [user, setUser] = useState({});
  const [product, setProduct] = useState([]);

  const getAllProduct = () => {
    if (JSON.parse(localStorage.getItem("user")) === null) {
      axios.get(`/product`).then(({ data }) => {
        setProduct(data);
      });
    } else {
      axios
        .get(
          `/product?creator.id_ne=${
            JSON.parse(localStorage.getItem("user")).id
          }`
        )
        .then(({ data }) => {
          setProduct(data);
        });
    }
  };

  const getProductsById = (id) => {
    axios(`/product?creator.id=${id}`).then(({ data }) => {
      setProduct(data);
    });
  };

  const getUserFromLS = () => {};

  const value = {
    user,
    setUser,
    getAllProduct,
    product,
    getUserFromLS,
    getProductsById
  };
  return (
    <CustomContext.Provider value={value}>
      {props.children}
    </CustomContext.Provider>
  );
};
