import React, { useContext, useEffect } from "react";
import { CustomContext } from "../../Context";
import { useParams } from "react-router-dom";
import Card from "../Home/Card";

const AnotherAccount = () => {
  const params = useParams();
  const { product, getProductsById } = useContext(CustomContext);
  useEffect(() => {
    getProductsById(params.id);
  }, []);
  return (
    <div className="another">
      <div className="container">
        <div className="another__user">
          <img
            className="another__img"
            src={
              product[0].creator.avatar
                ? product[0].creator.avatar
                : "https://www.atoz-catering.co.uk/assets/default-profile-picture.png"
            }
            alt=""
          />
          <div>
            <h2 className="another__title">{product[0].creator.name}</h2>
            <p className="another__text">{product[0].creator.phone}</p>
            <p className="another__text">{product[0].creator.email}</p>
          </div>
        </div>
        <div className="home__content">
          {product.map((item) => (
            <Card item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnotherAccount;
