import React, { useState, useContext } from "react";
import { CustomContext } from "../../Context";
import { category } from "../../category";
import Select from "./Select";
import { useForm } from "react-hook-form";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";

const AddPost = () => {
  const [categoryActive, setCategoryActive] = useState(false);
  const [select, setSelect] = useState("");
  const [chose, setChose] = useState("");
  const [chose2, setChose2] = useState("");
  const { user, setUser } = useContext(CustomContext);
  console.log(user);
  const { handleSubmit, register, reset } = useForm();

  const navigate = useNavigate();

  const addPostHandler = (data) => {
    axios
      .post("/product", {
        ...data,
        creator: user,
        category: select,
        type: chose,
        commet: [],
        chose: chose2,
      })
      .then((res) => {
        reset();
        setChose("");
        setChose2("");
        setSelect("");
        setCategoryActive(false);
        navigate("/");
      })
      .catch((err) => alert(err));
  };

  return (
    <div className="post">
      <div className="container">
        <form
          onSubmit={handleSubmit(addPostHandler)}
          className="post__form"
          action=""
        >
          <h2 className="post__title">Разместить бесплатное обьявление</h2>
          <h3 className="post__text">Загрузите фото (до 10 штук)</h3>
          <input
            {...register("image")}
            className="post__input"
            placeholder="Добавьте фото"
            type="text"
          />
          <p className="post__descr">Описание</p>
          <textarea
            {...register("description")}
            className="post__textarea"
            placeholder="Добавьте описание"
            cols="30"
            rows="10"
          />
          <div className="post__category">
            <p className="post__category-title">Категория</p>
            <button
              className="post__category-btn"
              type={"button"}
              onClick={() => {
                setCategoryActive(true);
                setSelect("");
                setChose("");
                setChose2("");
              }}
            >
              Выбрать
            </button>
            <ul
              className="post__category-list"
              style={{ display: categoryActive ? "block" : "none" }}
            >
              {category
                .filter((item) => item.category.includes(select))
                .map((item) => (
                  <li className="post__category-item">
                    <span onClick={() => setSelect(item.category)}>
                      {item.category}
                    </span>
                    <ul style={{ display: select ? "block" : "none" }}>
                      {item.list
                        .filter((el) => el.category.includes(chose))
                        .map((el) => (
                          <li
                            className="post__category-item"
                            onClick={() => setChose(el.category)}
                          >
                            {el.category}
                            {el.list && (
                              <ul
                                className="post__category-list"
                                style={{
                                  display: chose.length ? "block" : "none",
                                }}
                              >
                                {el.list
                                  .filter((list) => list.name.includes(chose2))
                                  .map((list) => (
                                    <li
                                      className="post__category-item"
                                      onClick={() => setChose2(list.name)}
                                    >
                                      {list.name}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </li>
                        ))}
                    </ul>
                  </li>
                ))}
            </ul>
          </div>

          <Select
            register={register}
            formKey={"city"}
            title="Город"
            list={["Москва", "Краснодар"]}
          />

          <div>
            <h2 className="post__price">Цена</h2>
            <div>
              <input
                {...register("price")}
                className="post__input-currency"
                type="number"
              />
              <label className="post__label" htmlFor="">
                <span className="post__currency">RUB</span>
              </label>
            </div>
          </div>
          {select === "рыбалка" && (
            <div className="dynamic__block-fishing">
              <p>Ты рыбачка я рыбак</p>
            </div>
          )}
          {select === "охота" && (
            <div className="dynamic__block-hunting">
              <p>Ты охотник я медведь</p>
            </div>
          )}
          {select === "спорт" && (
            <div className="dynamic__block-sport">
              <p>Просто спорт</p>
            </div>
          )}
          <button className="post__category-btn" type="submit">
            Опубликовать
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddPost;
